import logo from './logo.svg';
import './App.css';
import SplashPage from './SplashPage';
import DrBellyNavbar from './components/DrBellyNavbar';
import DrBellyFooter from './components/DrBellyFooter';

function App() {
  return (
    <div className="App">
      <DrBellyNavbar color="dark" dark expand="md" />
      <SplashPage />
      <DrBellyFooter color="dark" dark expand="md" />

    </div>
  );
}

export default App;
