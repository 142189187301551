import React from 'react';
import { Container, Row, Col, Card, CardBody, CardGroup } from 'reactstrap';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { keyframes } from '@emotion/react';
import logo from './assets/bellyLogo.png';
import fire from './assets/fireBg.png';
import Sauces from './components/Sauces';
import About from './components/About';
import Locations from './components/Locations';
import Contact from './components/Contact';
import Events from './components/Events';
// Create a warm, modern theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#ff6f61', // warm red-orange
    },
    secondary: {
      main: '#ffc107', // warm yellow
    },
    background: {
      default: '#fef3e2', // soft, warm beige background
    },
    text: {
      primary: '#4e342e', // dark brown text for contrast
      secondary: '#5d4037', // lighter brown
    },
  },
  typography: {
    h2: {
      fontFamily: "'Montserrat', sans-serif",
      fontWeight: 700,
    },
    h5: {
      fontFamily: "'Montserrat', sans-serif",
    },
    body2: {
      fontFamily: "'Roboto', sans-serif",
    },
  },
});

// Keyframes for fade-in animation
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Wrapper styles for fade-in effect
const fadeInStyles = {
  animation: `${fadeIn} 1s ease-out forwards`,
};


const SplashPage = () => {
  return (
    <ThemeProvider theme={theme}>
        {/* Jumbotron */}
        <div className='jumbo' style={{...fadeInStyles, animationDelay: '0.2s' }}>
          <img
            src={logo}
            alt="Dr. Belly's Logo"
            style={{
              marginBottom: '1rem',
            }}
          />
        </div>
        <Container className="py-4" style={{ overflow: 'hidden' }}>
          <Sauces />
          <About />
          <Locations />
          {/* <Events /> */}
          <Contact />
      </Container>
    </ThemeProvider>
  );
};

export default SplashPage;
