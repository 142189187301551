import React, { useState } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';

function DrBellyFooter(args) {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div>
      <Navbar {...args}>
          <Nav className="mx-auto" navbar>
            <NavItem>
              <NavLink href="#"> © 2024 Copyright: Dr. Belly's</NavLink>
            </NavItem>
          </Nav>
      </Navbar>
    </div>
  );
}

export default DrBellyFooter;