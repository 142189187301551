import React from 'react';
import ovenLogo from '../assets/ovenessence.png'
const Locations = () => {
  return (
    <div className='mx-2 mt-5' id='locations'>
        <h1 className='section-header'>Now available at</h1>
        <div className='location-card'>
          <img src={ovenLogo} alt='Oven Essence logo' />
          <h2 className='mb-0'>Oven Essence</h2>
          <p>20 N Washington St, North Attleborough, MA</p>
          </div>
    </div>
  );
};

export default Locations;
