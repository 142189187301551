import React from 'react';
import { Card, CardBody, CardGroup } from 'reactstrap';
import hellBottle from '../assets/hell-bottle.jpg';
import suluBottle from '../assets/sulu-bottle.jpg';
import fireBottle from '../assets/fire-bottle.jpg';

const Sauces = () => {
  return (
    <div>
        <h1 className='section-header mx-2'>Our sauces</h1>
          <CardGroup id='sauces'>
            <Card className='sauce-card mb-4 mx-2'>
              <CardBody className='px-1'>
                <h2 style={{ fontWeight: 'bold' }}>Fire Tonic</h2>
                <img src={fireBottle} alt='Fire Tonic bottle' className='sauce-image' />
                <p>
                  A habanero garlic blend with a sweet touch (sweetened with erythritol).
                </p>
              </CardBody>
            </Card>
          <Card className='sauce-card mb-4 mx-2'>
              <CardBody className='px-1'>
                <h2 style={{ fontWeight: 'bold' }}>Sulu Sauce</h2>
                <img src={suluBottle} alt='Sulu Sauce bottle' className='sauce-image' />
                <p>
                  An emulsified jalapeno-based hot sauce for dipping. It has a guacamole-like
                  consistency made with jalapenos, garlic, and onions.
                </p>
              </CardBody>
            </Card>
          <Card className='sauce-card mb-4 mx-2'>
              <CardBody className='px-1'>
                <h2 style={{ fontWeight: 'bold' }}>Taco Hell</h2>
                <img src={hellBottle} alt='Taco Hell bottle' className='sauce-image' />
                <p>
                  A cumin-heavy hot sauce, similar to Taco Bell's fire sauce but made with ghost
                  peppers.
                </p>
              </CardBody>
            </Card>
          </CardGroup>
          </div>
  );
};

export default Sauces;
