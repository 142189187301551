import React from 'react';

const Contact = () => {
  return (
    <div className='mx-2 mt-5' id='contact'>
        <h1 className='section-header'>Contact us</h1>
        <p className='text-start'>For all inquiries, contact us at <a href='mailto:info@drbellys.com'>info@drbellys.com</a></p>
    </div>
  );
};

export default Contact;