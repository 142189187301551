import React, { useState } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';

function DrBellyNavbar(args) {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div>
      <Navbar {...args}>
        <NavbarBrand href="/">Dr. Belly's</NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="me-auto" navbar>
            <NavItem>
              <NavLink href="#sauces">Sauces</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#about">
                About
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#locations">
                Locations
              </NavLink>
            </NavItem>
            {/* <NavItem>
              <NavLink href="#events">
                Events
              </NavLink>
            </NavItem> */}
            <NavItem>
              <NavLink href="#contact">
                Contact
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
}

export default DrBellyNavbar;